import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {
    Box, 
    IconButton, 
    Typography, 
    Slider, 
    CircularProgress, 
    Grid, 
    Tabs, 
    Tab,
    Button,
    TextField,
    Tooltip,
    Paper,
    Popover,
    Fade
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import SpeedIcon from '@mui/icons-material/Speed';
import './Heartbeat.css';
import HeartbeatTimeline from "./HeartbeatTimeline";
import Transcription from "../transcript/Transcription";
import config from '../../../config';
import ChatWithAI from "./ChatWithAI";


const MIN_SPEED = 0.1;
const MAX_SPEED = 2.0;
const DEFAULT_SPEED = 1.0;


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Heartbeat = ({transcriptData}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [transcriptState, setTranscriptState] = useState(transcriptData);
    const [tabIndex, setTabIndex] = useState(0);
    const [meetingIntelligence, setMeetingIntelligence] = useState(null);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const audioRef = useRef(null);
    const [showSpeedControls, setShowSpeedControls] = useState(false);
    const [speedInputValue, setSpeedInputValue] = useState(DEFAULT_SPEED.toString());
    const speedControlsRef = useRef(null);
    const [isChatExpanded, setIsChatExpanded] = useState(false);
    const [speedAnchorEl, setSpeedAnchorEl] = useState(null);

    // Convert duration to milliseconds, handling floating point precision
    const durationMs = Math.round(duration * 1000);
    const currentTimeMs = Math.round(currentTime * 1000);


    const handleSpeedClick = (event) => {
        setSpeedAnchorEl(event.currentTarget);
    };

    const handleSpeedClose = () => {
        setSpeedAnchorEl(null);
    };

    const handleSpeedSliderChange = (event, newValue) => {
        setPlaybackSpeed(newValue);
        setSpeedInputValue(newValue.toFixed(1));
        const audio = audioRef.current;
        if (audio) {
            audio.playbackRate = newValue;
        }
    };

    const handleSpeedInputChange = (event) => {
        const value = event.target.value;
        setSpeedInputValue(value);
        
        const numValue = parseFloat(value);
        if (!isNaN(numValue) && numValue >= MIN_SPEED && numValue <= MAX_SPEED) {
            setPlaybackSpeed(numValue);
            const audio = audioRef.current;
            if (audio) {
                audio.playbackRate = numValue;
            }
        }
    };

    const handleSpeedInputBlur = () => {
        const numValue = parseFloat(speedInputValue);
        if (isNaN(numValue) || numValue < MIN_SPEED || numValue > MAX_SPEED) {
            setSpeedInputValue(playbackSpeed.toFixed(1));
        }
    };



    useEffect(() => {
        if (transcriptData && transcriptData.metaData && transcriptData.metaData.meetingId) {
            fetchAudioUrl(transcriptData.metaData.meetingId);
            fetchMeetingIntelligence(transcriptData.metaData.meetingId);
        }
    }, [transcriptData]);

    useEffect(() => {
        if (transcriptData) {
            setTranscriptState(prevState => ({
                ...prevState,
                ...transcriptData
            }));
        }
    }, [transcriptData]);

    const fetchAudioUrl = async (meetingId) => {
        setIsLoadingAudio(true);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-audio-file/${meetingId}/`);
            setAudioUrl(response.data.url);
        } catch (error) {
            console.error('Error fetching audio URL:', error);
        } finally {
            setIsLoadingAudio(false);
        }
    };

    const fetchMeetingIntelligence = async (meetingId) => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-meeting-intelligence/${meetingId}/`);
            setMeetingIntelligence(response.data);
        } catch (error) {
            console.error('Error fetching meeting intelligence:', error);
        }
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.src = audioUrl;
            audioRef.current.load();
        }
    }, [audioUrl]);

    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.playbackRate = playbackSpeed;
        }
    }, [playbackSpeed]);

    useEffect(() => {
        const audio = audioRef.current;
        const updateTime = () => {
            setCurrentTime(audio.currentTime);
        };
        const setAudioData = () => {
            setDuration(audio.duration);
        };
        audio.addEventListener('timeupdate', updateTime);
        audio.addEventListener('loadedmetadata', setAudioData);
        return () => {
            audio.removeEventListener('timeupdate', updateTime);
            audio.removeEventListener('loadedmetadata', setAudioData);
        };
    }, []);

    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.playbackRate = playbackSpeed;
        }
    }, [playbackSpeed]);

    // Handle click outside speed controls
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (speedControlsRef.current && !speedControlsRef.current.contains(event.target)) {
                setShowSpeedControls(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const toggleSpeedControls = () => {
        setShowSpeedControls(!showSpeedControls);
    };


    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    const skipTime = (seconds) => {
        const audio = audioRef.current;
        audio.currentTime = Math.min(Math.max(0, audio.currentTime + seconds), duration);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleSeek = (event, newValue) => {
        const audio = audioRef.current;
        audio.currentTime = newValue;
        setCurrentTime(newValue);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleTimeChange = (newTime) => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    const handleSpeedChange = (event) => {
        setPlaybackSpeed(event.target.value);
    };

    const handleDownload = async () => {
        if (audioUrl) {
            try {
                const response = await fetch(audioUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `meeting-${transcriptData.metaData.meetingId}.mp3`; // or appropriate extension
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } catch (error) {
                console.error('Error downloading audio:', error);
            }
        }
    };

    const toggleChatExpand = () => {
        setIsChatExpanded(!isChatExpanded);
    };

    return (
        <Grid container spacing={1} className="heartbeat-container">
            <Grid 
                item 
                xs={12} 
                md={isChatExpanded ? 4 : 8}
                className="playback-grid"
                sx={{
                    transition: 'all 0.3s ease-in-out'
                }}
            >
                <Box className="playback-bar" sx={{ position: 'relative' }}>
                    {isLoadingAudio ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                            <CircularProgress/>
                        </Box>
                    ) : (
                        <>
                            <Box className="slider-container">
                                <Slider
                                    className="seek-bar"
                                    value={currentTime}
                                    max={duration}
                                    onChange={handleSeek}
                                    aria-labelledby="continuous-slider"
                                    sx={{ color: 'primary.main' }} // Using standard MUI blue
                                />
                            </Box>
                            <Box className="controls" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Typography variant="body2" className="time-display">
                                    {formatTime(currentTime)} / {formatTime(duration)}
                                </Typography>
                                <Box className="playback-controls" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <IconButton onClick={() => skipTime(-15)}>
                                        <SkipPreviousIcon/>
                                    </IconButton>
                                    <IconButton onClick={togglePlayPause}>
                                        {isPlaying ? <PauseIcon/> : <PlayArrowIcon/>}
                                    </IconButton>
                                    <IconButton onClick={() => skipTime(15)}>
                                        <SkipNextIcon/>
                                    </IconButton>
                                    
                                    <Tooltip title="Playback Speed">
                                        <IconButton 
                                            onClick={handleSpeedClick}
                                            sx={{ 
                                                color: speedAnchorEl || playbackSpeed !== 1 ? 'primary.main' : 'inherit'
                                            }}
                                        >
                                            <SpeedIcon />
                                            <Typography variant="caption" sx={{ ml: 0.5 }}>
                                                {playbackSpeed.toFixed(1)}x
                                            </Typography>
                                        </IconButton>
                                    </Tooltip>

                                    <Popover
                                        open={Boolean(speedAnchorEl)}
                                        anchorEl={speedAnchorEl}
                                        onClose={handleSpeedClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Box sx={{ p: 2, width: 300 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                                <Typography sx={{ mr: 2 }}>Speed:</Typography>
                                                <TextField
                                                    value={speedInputValue}
                                                    onChange={handleSpeedInputChange}
                                                    onBlur={handleSpeedInputBlur}
                                                    size="small"
                                                    type="number"
                                                    inputProps={{
                                                        step: 0.1,
                                                        min: MIN_SPEED,
                                                        max: MAX_SPEED,
                                                        style: { width: '80px' }
                                                    }}
                                                />
                                            </Box>
                                            <Slider
                                                value={playbackSpeed}
                                                onChange={handleSpeedSliderChange}
                                                min={MIN_SPEED}
                                                max={MAX_SPEED}
                                                step={0.1}
                                                marks={[
                                                    { value: 0.5, label: '0.5x' },
                                                    { value: 1, label: '1x' },
                                                    { value: 1.5, label: '1.5x' },
                                                    { value: 2, label: '2x' }
                                                ]}
                                            />
                                        </Box>
                                    </Popover>

                                    <Button
                                        startIcon={<DownloadIcon />}
                                        onClick={handleDownload}
                                        disabled={!audioUrl}
                                        variant="outlined"
                                        size="small"
                                    >
                                        Download
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
                <Box className="timeline-container">
                    <HeartbeatTimeline
                        transcriptData={transcriptData}
                        meetingIntelligence={meetingIntelligence}
                        duration={duration}
                        currentTime={currentTime}
                        onTimeChange={handleTimeChange}
                    />
                </Box>
                <audio ref={audioRef}/>
            </Grid>
            <Grid 
                item 
                xs={12} 
                md={isChatExpanded ? 8 : 4}
                className="tab-view-grid"
                sx={{
                    transition: 'all 0.3s ease-in-out'
                }}
            >
                <Paper 
                    elevation={3}
                    sx={{
                        position: 'relative',
                        height: '100%',
                        overflow: 'hidden'
                    }}
                >
                    <Box 
                        sx={{ 
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 2
                        }}
                    >
                        <Tooltip 
                            title={isChatExpanded ? "Collapse" : "Expand"} 
                            placement="left"
                        >
                            <IconButton
                                onClick={toggleChatExpand}
                                size="small"
                                sx={{
                                    backgroundColor: 'background.paper',
                                    '&:hover': {
                                        backgroundColor: 'action.hover'
                                    },
                                    boxShadow: 1,
                                    transition: 'all 0.2s ease-in-out',
                                    transform: isChatExpanded ? 'rotate(180deg)' : 'none'
                                }}
                            >
                                {isChatExpanded ? 
                                    <CloseFullscreenIcon fontSize="small" /> : 
                                    <OpenInFullIcon fontSize="small" />
                                }
                            </IconButton>
                        </Tooltip>
                    </Box>
                    
                    <Fade in={true}>
                        <Box className="grid-item grid-item-transcription heartbeat-tabview">
                            <Tabs 
                                value={tabIndex} 
                                onChange={handleTabChange} 
                                aria-label="Heartbeat Tabs"
                                sx={{ 
                                    borderBottom: 1, 
                                    borderColor: 'divider',
                                    marginBottom: 1
                                }}
                            >
                                <Tab label="Transcription"/>
                                <Tab label="Ask Elevaide"/>
                            </Tabs>
                            <Box 
                                sx={{
                                    height: 'calc(100% - 48px)',
                                    overflow: 'auto'
                                }}
                            >
                                <TabPanel value={tabIndex} index={0}>
                                    <Transcription data={transcriptState} setData={setTranscriptState}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={1}>
                                    <ChatWithAI transcriptData={transcriptState}/>
                                </TabPanel>
                            </Box>
                        </Box>
                    </Fade>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Heartbeat;
