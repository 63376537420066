import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Typography,
    TextField,
    MenuItem,
    Button,
    Alert,
} from '@mui/material';
import ModalOverlay from '../../components/modal/ModalOverlay';
import config from '../../config';
import { UserMultiSelectionInput, UserSelectionInput } from '../../components/usersearchinput/UserSelection';

const CreateTeamPanel = ({ isOpen, onClose, teamId, onSave }) => {
    const [teamName, setTeamName] = useState('');
    const [description, setDescription] = useState('');
    const [manager, setManager] = useState(null);
    const [members, setMembers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [availableCoaches, setAvailableCoaches] = useState([]);
    const [selectedCoaches, setSelectedCoaches] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchUserCompanies = useCallback(async () => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/companies/user-companies/`);
            const userCompanies = response.data;
            setCompanies(userCompanies);
            if (userCompanies.length === 1) {
                setSelectedCompany(userCompanies[0]);
            }
        } catch (err) {
            console.error('Error fetching user companies:', err);
            setError('Failed to fetch companies. Please try again.');
        }
    }, []);

    const fetchCompanyUsers = useCallback(async (companyId) => {
        if (!companyId) return;
        try {
            setLoading(true);
            const response = await axios.get(`${config.backendUrlApiV1}/companies/${companyId}/users/`);
            setAvailableUsers(response.data.members || []);
            setAvailableCoaches(response.data.coaches || []);
        } catch (err) {
            console.error('Error fetching company users:', err);
            setError('Failed to fetch company users. Please try again.');
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchTeamData = useCallback(async () => {
        if (!teamId) return;
        try {
            setLoading(true);
            const response = await axios.get(`${config.backendUrlApiV1}/team/${teamId}/`);
            const teamData = response.data;

            setTeamName(teamData.name || '');
            setDescription(teamData.description || '');
            setManager(teamData.manager || null);
            setMembers(teamData.members || []);
            setSelectedCoaches(teamData.coaches || []);
            setSelectedCompany(teamData.company || null);

            if (teamData.company) {
                await fetchCompanyUsers(teamData.company.id);
            }
        } catch (err) {
            console.error('Error fetching team data:', err);
            setError('Failed to fetch team data. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [teamId, fetchCompanyUsers]);

    useEffect(() => {
        fetchUserCompanies();
    }, [fetchUserCompanies]);

    useEffect(() => {
        if (isOpen) {
            if (teamId) {
                fetchTeamData();
            } else {
                resetForm();
            }
        }
    }, [isOpen, teamId, fetchTeamData]);

    const resetForm = () => {
        setTeamName('');
        setDescription('');
        setManager(null);
        setMembers([]);
        setSelectedCoaches([]);
        setError('');
        if (companies.length === 1) {
            fetchCompanyUsers(companies[0].id);
            setSelectedCompany(companies[0]);
        }
    };

    const handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const company = companies.find((c) => c.id === companyId);
        setSelectedCompany(company);
        setManager(null);
        setMembers([]);
        setSelectedCoaches([]);
        fetchCompanyUsers(companyId);
    };

    const handleUserSelect = (role, user) => {
        if (role === 'members') {
            setMembers((prev) => [...prev, user]);
        } else if (role === 'coaches') {
            setSelectedCoaches((prev) => [...prev, user]);
        } else if (role === 'manager') {
            setManager(user);
        }
    };
    
    const handleUserRemove = (role, userId) => {
        if (role === 'members') {
            setMembers((prev) => prev.filter((user) => user.id !== userId));
        } else if (role === 'coaches') {
            setSelectedCoaches((prev) => prev.filter((user) => user.id !== userId));
        } else if (role === 'manager') {
            setManager(null);
        }
    };

    const handleSave = async () => {
        try {
            // Validate required fields
            if (!selectedCompany?.id) {
                setError('Company is required.');
                return;
            }
    
            if (!teamName.trim()) {
                setError('Team name is required.');
                return;
            }
    
            setLoading(true);
            setError('');
    
            const teamData = {
                name: teamName.trim(),
                description: description.trim(),
                company_id: selectedCompany.id,
                manager_id: manager?.id || null,
                members_ids: members.map(m => m.id),
                coaches_ids: selectedCoaches.map(c => c.id)
            };
    
            let response;
            if (teamId) {
                response = await axios.put(
                    `${config.backendUrlApiV1}/team/${teamId}/`, 
                    teamData
                );
            } else {
                response = await axios.post(
                    `${config.backendUrlApiV1}/team/`, 
                    teamData
                );
            }
    
            if (response.data) {
                onSave(response.data);
                onClose();
            }
        } catch (err) {
            console.error('Error saving team:', err);
            if (err.response?.data?.error) {
                // Use the specific error message from the backend
                setError(err.response.data.error);
            } else {
                setError('An error occurred while saving the team. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <ModalOverlay title={teamId ? 'Edit Team' : 'Create Team'} isOpen={isOpen} onClose={onClose}>
            {error && <Alert severity="error">{error}</Alert>}
            <Typography>Company</Typography>
            <TextField
                select
                value={selectedCompany?.id || ''}
                onChange={handleCompanyChange}
                fullWidth
                margin="normal"
                required
                disabled={loading || companies.length <= 1}
            >
                {companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                        {company.name}
                    </MenuItem>
                ))}
            </TextField>

            <Typography>Team Name</Typography>
            <TextField
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                fullWidth
                margin="normal"
                required
                disabled={loading}
            />

            <UserMultiSelectionInput
                role="coaches"
                label="Coaches"
                availableUsers={availableCoaches.filter(
                    (c) => !selectedCoaches.some((s) => s.id === c.id)
                )}
                selectedUsers={selectedCoaches}
                onSelect={(role, user) => handleUserSelect(role, user)}
                onRemove={(role, userId) => handleUserRemove(role, userId)}
            />

            <UserSelectionInput
                role="manager"
                label="Manager"
                availableUsers={availableUsers.filter((u) => !manager || u.id !== manager.id)}
                selectedUser={manager}
                onSelect={(role, user) => handleUserSelect(role, user)}
                onRemove={(role, userId) => handleUserRemove(role, userId)}
                placeholder="Search for a manager"
            />

            <UserMultiSelectionInput
                role="members"
                label="Members"
                availableUsers={availableUsers.filter((u) => !members.some((m) => m.id === u.id))}
                selectedUsers={members}
                onSelect={(role, user) => handleUserSelect(role, user)}
                onRemove={(role, userId) => handleUserRemove(role, userId)}
            />

            <Button onClick={handleSave} disabled={loading}>
                {loading ? 'Saving...' : 'Save'}
            </Button>
        </ModalOverlay>
    );
};

export default CreateTeamPanel;
