import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    IconButton,
    useMediaQuery,
    useTheme,
    Tabs,
    Tab,
    Grid,
    Paper,
    Button,
    createTheme,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import {
    ChevronLeft,
    ChevronRight,
    CalendarToday,
    Schedule,
    Timer,
    Close,
    OpenInNew,
    ExpandMore,
    Edit,
    PersonOutline as PersonOutlineIcon,
    CalendarToday as CalendarTodayIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
    InfoOutlined as InfoOutlinedIcon
} from '@mui/icons-material';
import axios from "axios";
import config from "../config";
import TranscriptEditor from "./home/transcript/TranscriptEditor";
import { RefreshProvider} from "./../auth/RefreshProvider";
import theme from './../theme';
import TeamFilterDropdown from './../components/TeamFilterDropdown';
import { useQuery, useQueryClient } from 'react-query';

const numberOfRecordsPerPage = 25;

const MeetingCard = ({ meeting, isSelected, onClick, isDisabled }) => {
    const theme = useTheme();

    const renderDetail = (Icon, label) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Icon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
            <Typography variant="caption" color="text.secondary">
                {label}
            </Typography>
        </Box>
    );

    return (
        <Card
            onClick={() => !isDisabled && onClick(meeting)}
            sx={{
                borderRadius: 2,
                boxShadow: 2,
                cursor: isDisabled ? 'default' : 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                opacity: isDisabled ? 0.7 : 1,
                border: '1px solid rgba(0, 0, 0, 0.08)',
                '&:hover': !isDisabled ? {
                    transform: 'scale(1.02)',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                    '& .meeting-title': {
                        color: theme.palette.primary.main,
                        fontWeight: 600
                    }
                } : {},
                backgroundColor: '#FFFFFF'
            }}
        >
            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                <Box sx={{ mb: 1 }}>
                <Typography
                    className="meeting-title"
                    variant="h7"
                    component="div"
                    sx={{ 
                        color: isSelected ? theme.palette.primary.main : 'inherit',
                        mb: 1,
                        fontWeight: isSelected ? 600 : 'normal',
                        '&:hover': {
                            color: theme.palette.primary.main,
                            fontWeight: 600
                        }
                    }}
                >
                    {meeting.name}
                </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {meeting.team || 'No Team'}
                    </Typography>
                </Box>

                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1 }}>
                    {renderDetail(CalendarToday, meeting.date)}
                    {renderDetail(Schedule, meeting.time)}
                    {renderDetail(Timer, meeting.duration)}
                </Box>
            </CardContent>
        </Card>
    );
};

const MeetingSummarySection = ({ summary, actionPoints, decisions }) => {
    return (
        <Box 
            sx={{ 
                height: '100%',
                overflow: 'auto',
                bgcolor: '#FAFAFA',
                px: 3,
                py: 4
            }}
        >
            {/* Summary */}
            <Box sx={{ mb: 4 }}>
                <Typography 
                    variant="h6" 
                    gutterBottom
                    sx={{ 
                        color: 'text.primary',
                        fontWeight: 500,
                        mb: 2
                    }}
                >
                    Summary
                </Typography>
                <Typography 
                    variant="body1" 
                    sx={{ 
                        color: 'text.primary',
                        lineHeight: 1.7,
                        whiteSpace: 'pre-wrap',
                        fontSize: '0.875rem'
                    }}
                >
                    {summary}
                </Typography>
            </Box>

            {/* Action Points */}
            <Box sx={{ mb: 4 }}>
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                }}>
                    <Typography 
                        variant="h6"
                        sx={{ 
                            color: 'text.primary',
                            fontWeight: 500
                        }}
                    >
                        Action Points
                    </Typography>
                    {actionPoints?.length > 0 && (
                        <Chip 
                            size="small"
                            label={actionPoints.length}
                            sx={{ ml: 2 }}
                        />
                    )}
                </Box>
                {actionPoints?.length > 0 ? (
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>
                        {actionPoints.map((action, index) => (
                            <Box 
                                key={index}
                                sx={{ 
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    bgcolor: 'white',
                                    p: 2,
                                    borderRadius: 1,
                                    boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
                                }}
                            >
                                <Chip 
                                    size="small"
                                    label={`${index + 1}`}
                                    sx={{ 
                                        mr: 2,
                                        mt: 0.5,
                                        bgcolor: 'transparent',
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        color: 'text.secondary',
                                        fontWeight: 500
                                    }}
                                />
                                <Typography 
                                    variant="body2"
                                    sx={{ flex: 1 }}
                                >
                                    {action}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ fontStyle: 'italic' }}
                    >
                        No action points were identified
                    </Typography>
                )}
            </Box>

            {/* Decisions */}
            <Box>
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                }}>
                    <Typography 
                        variant="h6"
                        sx={{ 
                            color: 'text.primary',
                            fontWeight: 500
                        }}
                    >
                        Decisions
                    </Typography>
                    {decisions?.length > 0 && (
                        <Chip 
                            size="small"
                            label={decisions.length}
                            sx={{ ml: 2 }}
                        />
                    )}
                </Box>
                {decisions?.length > 0 ? (
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>
                        {decisions.map((decisionObj, index) => (
                            <Box 
                                key={index}
                                sx={{ 
                                    bgcolor: 'white',
                                    p: 2,
                                    borderRadius: 1,
                                    boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
                                }}
                            >
                                <Box sx={{ 
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    mb: decisionObj.context ? 1 : 0
                                }}>
                                    <Typography 
                                        variant="body2"
                                        sx={{ 
                                            mr: 2,
                                            mt: 0.5,
                                            color: 'text.secondary',
                                            fontWeight: 500
                                        }}
                                    >
                                        {`D${index + 1}`}
                                    </Typography>
                                    <Typography 
                                        variant="body2"
                                        sx={{ flex: 1 }}
                                    >
                                        {decisionObj.decision}
                                    </Typography>
                                </Box>
                                {decisionObj.context && (
                                    <Typography 
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ 
                                            ml: 6,
                                            fontSize: '0.875rem',
                                            fontStyle: 'italic'
                                        }}
                                    >
                                        {decisionObj.context}
                                    </Typography>
                                )}
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ fontStyle: 'italic' }}
                    >
                        No decisions were recorded
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

const TranscriptView = ({ transcript, loading, summary }) => {
    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    // Get the speaker names array from the transcript
    const speakerNames = transcript?.speaker_names || [];

    return (
        <Grid container sx={{ height: '100%' }}>
            {/* Left side - Meeting Summary */}
            <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', height: '100%', overflow: 'auto' }}>
                <MeetingSummarySection 
                    summary={summary?.summary || "No summary available"}
                    actionPoints={summary?.action_items || []}
                    decisions={summary?.decisions || []}
                />
            </Grid>

            {/* Right side - Transcript */}
            <Grid item xs={8} sx={{ height: '100%', overflow: 'auto' }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>Transcript</Typography>
                    <Paper 
                        elevation={1} 
                        sx={{ 
                            p: 3, 
                            bgcolor: 'background.paper',
                            borderRadius: 0,
                            boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
                        }}
                    >
                        {transcript?.utterances ? (
                            <Box>
                                {transcript.utterances.map((utterance, index) => {
                                    // Get the speaker name from the speaker_names array using the speakerId
                                    const speakerName = speakerNames[utterance.speakerId - 1] || `Speaker ${utterance.speakerId}`;

                                    return (
                                        <Box
                                            key={index}
                                            component="div"
                                            sx={{
                                                py: 1.5,
                                            }}
                                        >
                                            <Typography 
                                                variant="subtitle2" 
                                                sx={{ 
                                                    color: 'text.primary',
                                                    fontWeight: 600,
                                                    fontSize: '0.875rem',
                                                    mb: 0.5,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                }}
                                            >
                                                {speakerName}
                                                <Typography 
                                                    variant="caption" 
                                                    sx={{ color: 'text.secondary' }}
                                                >
                                                    • {utterance.startTimeFormatted}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="body2">
                                                {utterance.text}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        ) : (
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography color="text.secondary">
                                    No transcript available
                                </Typography>
                            </Box>
                        )}
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
};
    
  
const PaginationControls = ({ currentPage, totalPages, totalRecords, onPageChange }) => (
    <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        py: 2,
        px: 1,
        borderTop: 1,
        borderColor: 'divider',
        mt: 2
    }}>
        <Typography variant="body2" color="text.secondary">
            {`${(currentPage - 1) * numberOfRecordsPerPage + 1}-${Math.min(currentPage * numberOfRecordsPerPage, totalRecords)} of ${totalRecords}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton 
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                size="small"
                sx={{ 
                    backgroundColor: theme => theme.palette.grey[100],
                    '&:hover': {
                        backgroundColor: theme => theme.palette.grey[200],
                    }
                }}
            >
                <ChevronLeft />
            </IconButton>
            <IconButton
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                size="small"
                sx={{ 
                    backgroundColor: theme => theme.palette.grey[100],
                    '&:hover': {
                        backgroundColor: theme => theme.palette.grey[200],
                    }
                }}
            >
                <ChevronRight />
            </IconButton>
        </Box>
    </Box>
);



const Meetings = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [paginationStates, setPaginationStates] = useState({});
    const pollingInterval = useRef(null);
    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [teamFilter, setTeamFilter] = useState(null);

    const POLLING_INTERVAL = 20000;
    const MAX_POLLING_TIME = 600000;
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(false);

    // Use React Query for meetings data with pagination
    const { data: meetingSets = [], isLoading: meetingsLoading, error: meetingsError } = useQuery(
        ['meetings', activeTab, paginationStates[activeTab]?.currentPage],
        async () => {
            const currentPage = paginationStates[activeTab]?.currentPage || 1;
            const response = await axios.get(`${config.backendUrlApiV1}/meeting/weekly_view/`, {
                params: {
                    page: currentPage
                }
            });
            return response.data.data || [];
        },
        {
            staleTime: 5 * 60 * 1000, // 5 minutes
            cacheTime: 30 * 60 * 1000, // 30 minutes
            keepPreviousData: true, // Keep showing previous data while fetching new data
            refetchOnWindowFocus: false // Prevent unnecessary refetches
        }
    );

    // Initialize pagination state
    useEffect(() => {
        if (meetingSets.length > 0) {
            const initialPaginationStates = {};
            meetingSets.forEach(set => {
                initialPaginationStates[set.userId] = {
                    currentPage: 1,
                    totalPages: set.totalPages,
                    totalRecords: set.totalRecords
                };
            });
            setPaginationStates(initialPaginationStates);
        }
    }, [meetingSets]);

    // Handle meeting updates
    const updateMeeting = (meetingId, updates) => {
        queryClient.setQueryData(['meetings', activeTab, paginationStates[activeTab]?.currentPage], 
            oldData => {
                if (!oldData) return oldData;
                return oldData.map(set => ({
                    ...set,
                    meetings: set.meetings.map(week => ({
                        ...week,
                        items: week.items.map(meeting =>
                            meeting.id === meetingId ? { ...meeting, ...updates } : meeting
                        )
                    }))
                }));
            }
        );
    };

    // Modified checkMeetingStatus to use updateMeeting
    const checkMeetingStatus = useCallback(async () => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet?.meetings) return;

        const unreadyMeetings = currentSet.meetings
            .flatMap(week => week.items)
            .filter(meeting => !meeting.ready)
            .map(meeting => meeting.id);

        if (unreadyMeetings.length === 0) {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
            }
            return;
        }

        try {
            const response = await axios.get(`${config.backendUrlApiV1}/meeting/check_status/`, {
                params: {
                    meeting_ids: unreadyMeetings.join(',')
                }
            });

            const {completedMeetings} = response.data;
            if (completedMeetings?.length > 0) {
                completedMeetings.forEach(meetingId => {
                    updateMeeting(meetingId, { ready: true });
                });
            }
        } catch (error) {
            console.error('Error checking meeting status:', error);
        }
    }, [meetingSets, activeTab, queryClient]);

    const fetchMeetingDetails = useCallback(async (meetingId) => {
        if (!meetingId) return;
    
        setDetailsLoading(true);
        try {
            // Check cache first
            const cachedData = sessionStorage.getItem(`meeting_details_${meetingId}`);
            if (cachedData) {
                const data = JSON.parse(cachedData);
                setDetails({
                    summary: data.summary,
                    transcript: data.transcript,
                    metaData: data.metaData,
                });
                return;
            }

            const response = await axios.get(`${config.backendUrlApiV1}/get-meeting-metrics/`, {
                params: {
                    selectedMeetingId: meetingId,
                    getTranscript: true,
                    getSurvey: false,
                    getSummary: true,
                },
            });
    
            const data = response.data;
            // Cache the response
            sessionStorage.setItem(`meeting_details_${meetingId}`, JSON.stringify(data));
            
            setDetails({
                summary: data.summary,
                transcript: data.transcript,
                metaData: data.metaData,
            });
    
            // Update meetingSets with the new data
            updateMeeting(meetingId, data.metaData);
        } catch (error) {
            console.error('Error fetching meeting details:', error);
        } finally {
            setDetailsLoading(false);
        }
    }, []);

    const startPolling = useCallback(() => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
        }

        const startTime = Date.now();
        checkMeetingStatus();

        pollingInterval.current = setInterval(() => {
            if (Date.now() - startTime > MAX_POLLING_TIME) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
                return;
            }
            checkMeetingStatus();
        }, POLLING_INTERVAL);
    }, [checkMeetingStatus]);

    useEffect(() => {
        startPolling();
        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
            }
        };
    }, [meetingSets, activeTab, startPolling]);

    const handleEditorOpen = () => {
        setIsEditorOpen(true);
    };

    const handleEditorClose = () => {
        setIsEditorOpen(false);
    };

    const handleEditorSave = (updatedDetails) => {
        if (selectedMeeting?.id) {
            updateMeeting(selectedMeeting.id, updatedDetails);
            setSelectedMeeting((prev) => ({ ...prev, ...updatedDetails })); // Update selectedMeeting state
            setIsEditorOpen(false);
        }
    };
    

    // Modify handleMeetingClick to fetch details
    const handleMeetingClick = (meeting) => {
        if (!meeting.ready) return;
        setSelectedMeeting(meeting);
        setDrawerOpen(true);
        fetchMeetingDetails(meeting.id);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedMeeting(null);
        setDetails(null);  // Clear details when closing
    };

    const handleViewDetails = (meetingId) => {
        window.open(`/product/meeting/${meetingId}`, '_blank');
    };

    const handlePageChange = (newPage) => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet) return;

        const currentPagination = paginationStates[currentSet.userId];
        if (newPage >= 1 && newPage <= currentPagination.totalPages) {
            setPaginationStates(prev => ({
                ...prev,
                [currentSet.userId]: {
                    ...prev[currentSet.userId],
                    currentPage: newPage
                }
            }));
            queryClient.invalidateQueries(['meetings', activeTab, newPage]);
        }
    };

    const getCurrentPagination = useCallback(() => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet) return {currentPage: 1, totalPages: 1, totalRecords: 0};

        return paginationStates[currentSet.userId] || {
            currentPage: 1,
            totalPages: 1,
            totalRecords: 0
        };
    }, [activeTab, meetingSets, paginationStates]);


    const renderContent = () => {
        if (meetingsLoading) {
            return (
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    minHeight: '200px'
                }}>
                    <CircularProgress />
                </Box>
            );
        }
    
        const currentMeetingSet = meetingSets[activeTab];
        if (!currentMeetingSet?.meetings?.length) {
            return (
                <Paper 
                    elevation={0}
                    sx={{ 
                        p: 4, 
                        textAlign: 'center',
                        backgroundColor: theme => theme.palette.grey[50],
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 2
                    }}
                >
                    <Typography color="text.secondary">
                        No meetings to display
                    </Typography>
                </Paper>
            );
        }
    
        
        const filteredMeetings = currentMeetingSet.meetings.map(week => ({
            ...week,
            items: week.items.filter(meeting => {
                if (teamFilter === null) return true;  // All meetings
                if (teamFilter === undefined) {
                    return !meeting.team;  // Only meetings with no team
                }
                return meeting.team === teamFilter;  // Specific team
            })
        })).filter(week => week.items.length > 0);
        
    
        return (
            <Box sx={{ overflow: 'auto' }}>
                <Box sx={{ mb: 3, mt: 2, mx: 0.5 }}>
                    <TeamFilterDropdown 
                        meetings={currentMeetingSet.meetings}
                        onFilterChange={setTeamFilter}
                        currentFilter={teamFilter}
                    />
                </Box>
    
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 1 
                }}>
                    {filteredMeetings.flatMap(week => week.items).map((meeting) => (
                        <MeetingCard
                            key={meeting.id}
                            meeting={meeting}
                            onClick={handleMeetingClick}
                            isDisabled={!meeting.ready}
                            isSelected={selectedMeeting?.id === meeting.id}
                        />
                    ))}
                </Box>
                <PaginationControls
                    currentPage={getCurrentPagination().currentPage}
                    totalPages={getCurrentPagination().totalPages}
                    totalRecords={getCurrentPagination().totalRecords}
                    onPageChange={handlePageChange}
                />
            </Box>
        );
    };



    const MainContent = () => (
        <Box 
            sx={{ 
                width: { 
                    xs: '100%',
                    sm: '400px',
                    md: '450px'
                },
                minWidth: {
                    xs: '100%',
                    sm: '400px',
                    md: '450px'
                },
                mx: { xs: 2, sm: 4 },
                my: 3,
                backgroundColor: 'background.default',
                // borderRadius: 2,
                flexShrink: 0,
            }}
        >
            {meetingSets.length > 1 && (
                <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => setActiveTab(newValue)}
                    sx={{ 
                        mb: 4,
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .MuiTab-root': {
                            textTransform: 'none',
                            fontWeight: 500,
                            minWidth: 120,
                            color: 'text.secondary',
                            '&.Mui-selected': {
                                color: 'primary.main',
                            }
                        }
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {meetingSets.map((set) => (
                        <Tab 
                            key={set.userId}
                            label={set.tabName}
                        />
                    ))}
                </Tabs>
            )}
            {renderContent()}
        </Box>
    );

    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && children}
            </div>
        );
    };

    useEffect(() => {
        const validateToken = async () => {
            try {
                // Make a lightweight API call to validate token
                await axios.get(`${config.backendUrlApiV1}/meeting/weekly_view/`, {
                    params: { page: 1, limit: 1 }
                });
            } catch (error) {
                if (error.response?.status === 401) {
                    // Token is invalid, redirect to login
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    navigate('/sign-in-3b9fAll7c5aY0u8d1eN33d4e6a8iS7d5b9cL0V36a7d8e9b3');
                }
            }
        };

        // Run validation check every 5 minutes
        const tokenCheckInterval = setInterval(validateToken, 5 * 60 * 1000);
        validateToken(); // Run initial check

        return () => clearInterval(tokenCheckInterval);
    }, [navigate]);

    return (
        <Box 
            sx={{ 
                display: 'flex',
                width: '100%',
                backgroundColor: 'background.default',
                position: 'relative',
                gap: 0,
                justifyContent: 'flex-start',
                ml: { xs: 0, sm: 0 },
                pr: { xs: 0, md: 8 },
            }}
        >
            <Box sx={{ 
                display: 'flex',
                justifyContent: 'flex-start',
            }}>
                <MainContent />
            </Box>
    
            {drawerOpen && (
                <Box sx={{
                    flex: 1,
                    height: `calc(100vh - 64px)`,
                    mt: '64px',
                    position: 'sticky',
                    top: 0,
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    borderColor: 'divider',
                    flexShrink: 0,
                    px: 3
                }}>
                    {/* Details section on top */}
                    <Box sx={{
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        borderColor: 'divider',
                        mb: 3
                    }}>
                        <Box sx={{ 
                            backgroundColor: 'background.default',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 2
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {/* <Typography variant="h5" sx={{ fontWeight: 600, color: theme.palette.primary.main }}> */}
                                <Typography variant="h5">
                                    {selectedMeeting.name}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewDetails(selectedMeeting?.id)}
                                    endIcon={<OpenInNew sx={{ fontSize: '0.875rem' }} />}
                                    sx={{
                                        height: '32px',
                                        padding: '0 6px',
                                        color: '#4A4A4A',
                                        borderColor: config.elevaide_orange, // Updated color
                                        borderRadius: '12px',
                                        fontSize: '0.750rem',
                                        fontWeight: 400,
                                        textTransform: 'none',
                                        '&:hover': {
                                            borderColor: config.elevaide_orange, // Retain the border color on hover
                                        },
                                        '&:active': {
                                            transform: 'scale(0.98)',
                                        },
                                        '&:focus-visible': {
                                            outline: 'none',
                                            boxShadow: `0 0 0 3px rgba(217, 48, 37, 0.2)`,
                                        },
                                    }}
                                >
                                    View Insights
                                </Button>

                            </Box>
                            <IconButton onClick={handleDrawerClose} size="small">
                                <Close />
                            </IconButton>
                        </Box>
                        
                        <Box sx={{ p: 2 }}>
                            {/* <Typography variant="subtitle1" sx={{ mb: 1.5, fontWeight: 500 }}>
                                {selectedMeeting.name}
                            </Typography> */}
                            
                            <Grid container spacing={2}>
                                {/* Meeting metadata */}
                                <Grid item xs={6}>
                                    <Box sx={{ 
                                        p: 1,
                                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                        borderRadius: 1,
                                        border: '1px solid rgba(0, 0, 0, 0.06)'
                                    }}>
                                        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                                            Meeting Info
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Date
                                                </Typography>
                                                <Typography variant="body2">
                                                    {selectedMeeting.date}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Duration
                                                </Typography>
                                                <Typography variant="body2">
                                                    {selectedMeeting.duration}
                                                </Typography>
                                            </Box>
                                            {/* <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Attendees
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                                                    {details?.metaData?.meetingParticipants && details.metaData.meetingParticipants.slice(0, 3).map((participant, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={participant.name}
                                                            size="small"
                                                            sx={{ 
                                                                height: '20px',
                                                                '& .MuiChip-label': {
                                                                    fontSize: '0.75rem',
                                                                    px: 1
                                                                }
                                                            }}
                                                        />
                                                    ))}
                                                    {details?.metaData?.meetingParticipants && details.metaData.meetingParticipants.length > 3 && (
                                                        <Chip
                                                            label={`+${details.metaData.meetingParticipants.length - 3}`}
                                                            size="small"
                                                            sx={{ 
                                                                height: '20px',
                                                                '& .MuiChip-label': {
                                                                    fontSize: '0.75rem',
                                                                    px: 1
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Box> */}
                                        </Box>
                                    </Box>
                                </Grid>
    
                                {/* Team and participants */}
                                <Grid item xs={6}>
                                    <Box sx={{ 
                                        p: 1,
                                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                        borderRadius: 1,
                                        border: '1px solid rgba(0, 0, 0, 0.06)'
                                    }}>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between', 
                                            alignItems: 'center', 
                                            mb: 0.5
                                        }}>
                                        </Box>
                                        <Typography variant="caption" color="text.secondary">
                                                Team
                                        </Typography>
                                        <IconButton 
                                            size="medium" 
                                            onClick={handleEditorOpen}
                                            sx={{ p: 0.5 }}
                                        >
                                            <Edit sx={{ fontSize: '0.875rem' }} />
                                        </IconButton>
                                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                                            {details?.metaData?.meetingTeam?.name || 'No team specified'}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                                Participants
                                            </Typography>
                                        {details?.metaData?.meetingParticipants && (
                                            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                                                {details.metaData.meetingParticipants.slice(0, 3).map((participant, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={participant.name}
                                                        size="small"
                                                        sx={{ 
                                                            height: '20px',
                                                            '& .MuiChip-label': {
                                                                fontSize: '0.75rem',
                                                                px: 1
                                                            }
                                                        }}
                                                    />
                                                ))}
                                                {details.metaData.meetingParticipants.length > 3 && (
                                                    <Chip
                                                        label={`+${details.metaData.meetingParticipants.length - 3}`}
                                                        size="small"
                                                        sx={{ 
                                                            height: '20px',
                                                            '& .MuiChip-label': {
                                                                fontSize: '0.75rem',
                                                                px: 1
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
    
                    {/* Transcript section on bottom */}
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100vh - 64px - 300px)', // Adjust based on your header height and top section
                        overflow: 'hidden',
                        backgroundColor: 'background.paper',
                        borderColor: 'divider',
                    }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={selectedTab} onChange={handleTabChange}>
                                <Tab label="Summary" />
                                <Tab label="Transcript" />
                                <Tab label={`Action Points (${details?.summary?.actionItems?.length || 0})`} />
                                <Tab label={`Decisions (${details?.summary?.decisions?.length || 0})`} />
                            </Tabs>
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <TabPanel value={selectedTab} index={0}>
                                <Box sx={{ 
                                    height: 'calc(100vh - 64px - 400px)',
                                    overflow: 'auto',
                                    px: 2
                                }}>
                                    {details?.summary?.summary ? (
                                        <Box 
                                            sx={{ 
                                                p: 3,
                                                borderRadius: 2,
                                                bgcolor: 'background.paper',
                                                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                                border: '1px solid',
                                                borderColor: 'divider',
                                            }}
                                        >
                                            <Typography variant="body1" sx={{ whiteSpace: 'pre-line', lineHeight: 1.6 }}>
                                                {details.summary.summary}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box 
                                            sx={{ 
                                                display: 'flex', 
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                py: 8,
                                                textAlign: 'center'
                                            }}
                                        >
                                            <InfoOutlinedIcon 
                                                sx={{ 
                                                    fontSize: '3rem',
                                                    color: 'text.disabled',
                                                    mb: 2
                                                }}
                                            />
                                            <Typography variant="h6" color="text.disabled" gutterBottom>
                                                No Summary Yet
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Meeting summary will appear here once processed
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={1}>
                                <Box sx={{ 
                                    height: 'calc(100vh - 64px - 400px)',
                                    overflow: 'auto',
                                    px: 2
                                }}>
                                    {details?.transcript?.utterances?.length > 0 ? (
                                        <Paper 
                                            elevation={1}
                                            sx={{ 
                                                p: 3, 
                                                bgcolor: 'background.paper',
                                                borderRadius: 0,
                                                boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
                                            }}
                                        >
                                            {details.transcript.utterances.map((utterance, index) => {
                                                return (
                                                    <Box 
                                                        key={index}
                                                        sx={{ 
                                                            py: 1.5,
                                                        }}
                                                    >
                                                        <Typography 
                                                            variant="subtitle2" 
                                                            sx={{ 
                                                                color: 'text.primary',
                                                                fontWeight: 600,
                                                                fontSize: '0.875rem',
                                                                mb: 0.5,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 1,
                                                            }}
                                                        >
                                                            {utterance.speakerName}
                                                            <Typography 
                                                                variant="caption" 
                                                                sx={{ color: 'text.secondary' }}
                                                            >
                                                                • {utterance.startTimeFormatted}
                                                            </Typography>
                                                        </Typography>
                                                        <Typography 
                                                            variant="body1"
                                                            sx={{ 
                                                                whiteSpace: 'pre-wrap',
                                                                lineHeight: 1.6,
                                                                color: 'text.primary'
                                                            }}
                                                        >
                                                            {utterance.text}
                                                        </Typography>
                                                    </Box>
                                                );
                                            })}
                                        </Paper>
                                    ) : (
                                        <Box 
                                            sx={{ 
                                                display: 'flex', 
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                py: 8,
                                                textAlign: 'center'
                                            }}
                                        >
                                            <InfoOutlinedIcon 
                                                sx={{ 
                                                    fontSize: '3rem',
                                                    color: 'text.disabled',
                                                    mb: 2
                                                }}
                                            />
                                            <Typography variant="h6" color="text.disabled" gutterBottom>
                                                No Transcript Available
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                The meeting transcript will appear here once processed
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={2}>
                                <Box sx={{ 
                                    height: 'calc(100vh - 64px - 400px)',
                                    overflow: 'auto',
                                    px: 2
                                }}>
                                    {details?.summary?.actionItems?.length > 0 ? (
                                        details.summary.actionItems.map((item, index) => (
                                            <Box 
                                                key={index}
                                                sx={{ 
                                                    mb: 3,
                                                    p: 3,
                                                    borderRadius: 2,
                                                    bgcolor: 'background.paper',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                                    transition: 'all 0.2s ease-in-out',
                                                    border: '1px solid',
                                                    borderColor: 'divider',
                                                    '&:hover': {
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                                        transform: 'translateY(-2px)',
                                                    }
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5, mb: 1.5 }}>
                                                    <Box
                                                        sx={{
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            bgcolor: 'transparent',
                                                            border: '1px solid',
                                                            borderColor: 'divider',
                                                            color: 'text.secondary',
                                                            fontSize: '0.875rem',
                                                            fontWeight: 500,
                                                            flexShrink: 0,
                                                            mt: 0.25
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </Box>
                                                    <Box sx={{ flex: 1 }}>
                                                        <Typography 
                                                            variant="subtitle1" 
                                                            sx={{ 
                                                                fontWeight: 500,
                                                                color: 'text.primary',
                                                                lineHeight: 1.4,
                                                                mb: 0.5
                                                            }}
                                                        >
                                                            {item.task}
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', gap: 2, color: 'text.secondary' }}>
                                                            {item.assignee && (
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                                    <PersonOutlineIcon sx={{ fontSize: '1rem' }} />
                                                                    <Typography variant="caption">
                                                                        {item.assignee}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                            {item.deadline && (
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                                    <CalendarTodayIcon sx={{ fontSize: '1rem' }} />
                                                                    <Typography variant="caption">
                                                                        {item.deadline}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {item.notes && (
                                                    <Box 
                                                        sx={{ 
                                                            ml: 5.25,
                                                            pl: 2,
                                                            borderLeft: '2px solid',
                                                            borderColor: 'divider'
                                                        }}
                                                    >
                                                        <Typography 
                                                            variant="body2" 
                                                            sx={{ 
                                                                color: 'text.secondary',
                                                                lineHeight: 1.6
                                                            }}
                                                        >
                                                            {item.notes}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))
                                    ) : (
                                        <Box 
                                            sx={{ 
                                                display: 'flex', 
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                py: 8,
                                                textAlign: 'center'
                                            }}
                                        >
                                            <InfoOutlinedIcon 
                                                sx={{ 
                                                    fontSize: '3rem',
                                                    color: 'text.disabled',
                                                    mb: 2
                                                }}
                                            />
                                            <Typography variant="h6" color="text.disabled" gutterBottom>
                                                No Action Points Yet
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Action points assigned during the meeting will appear here
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={3}>
                                <Box sx={{ 
                                    height: 'calc(100vh - 64px - 400px)',
                                    overflow: 'auto',
                                    px: 2
                                }}>
                                    {details?.summary?.decisions?.length > 0 ? (
                                        details.summary.decisions.map((item, index) => (
                                            <Box 
                                                key={index}
                                                sx={{ 
                                                    mb: 3,
                                                    p: 3,
                                                    borderRadius: 2,
                                                    bgcolor: 'background.paper',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                                    transition: 'all 0.2s ease-in-out',
                                                    border: '1px solid',
                                                    borderColor: 'divider',
                                                    '&:hover': {
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                                        transform: 'translateY(-2px)',
                                                    }
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5, mb: 1.5 }}>
                                                    <Box
                                                        sx={{
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            bgcolor: 'transparent',
                                                            border: '1px solid',
                                                            borderColor: 'divider',
                                                            color: 'text.secondary',
                                                            fontSize: '0.875rem',
                                                            fontWeight: 500,
                                                            flexShrink: 0,
                                                            mt: 0.25
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </Box>
                                                    <Typography 
                                                        variant="subtitle1" 
                                                        sx={{ 
                                                            fontWeight: 500,
                                                            color: 'text.primary',
                                                            lineHeight: 1.4
                                                        }}
                                                    >
                                                        {item.decision}
                                                    </Typography>
                                                </Box>
                                                {item.context && (
                                                    <Box 
                                                        sx={{ 
                                                            ml: 5.25,
                                                            pl: 2,
                                                            borderLeft: '2px solid',
                                                            borderColor: 'divider'
                                                        }}
                                                    >
                                                        <Typography 
                                                            variant="body2" 
                                                            sx={{ 
                                                                color: 'text.secondary',
                                                                lineHeight: 1.6
                                                            }}
                                                        >
                                                            {item.context}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))
                                    ) : (
                                        <Box 
                                            sx={{ 
                                                display: 'flex', 
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                py: 8,
                                                textAlign: 'center'
                                            }}
                                        >
                                            <InfoOutlinedIcon 
                                                sx={{ 
                                                    fontSize: '3rem',
                                                    color: 'text.disabled',
                                                    mb: 2
                                                }}
                                            />
                                            <Typography variant="h6" color="text.disabled" gutterBottom>
                                                No Decisions Yet
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Decisions made during the meeting will appear here
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </TabPanel>
                        </Box>
                    </Box>
                </Box>
            )}
    
            {details && (
                <RefreshProvider>
                    <TranscriptEditor
                        isOpen={isEditorOpen}
                        onClose={handleEditorClose}
                        onSave={handleEditorSave}
                        transcript={{
                            metaData: {
                                transcriptId: details.metaData.transcriptId,
                                meetingId: selectedMeeting.id,
                                ...details.metaData
                            },
                            ...details.transcript
                        }}
                    />
                </RefreshProvider>
            )}
        </Box>
    );
};

export default Meetings;
