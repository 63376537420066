import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Chip, Popover, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import TopicInfoCard from './TopicInfoCard';
import SignificantEventInfoCard from './SignificantEventInfoCard';
import { trackInteraction } from "../../../common/googleanalytics";

const TIMELINE_ROW_HEIGHT = 30;
const CHIP_HEIGHT = 24;

const HeartbeatTimeline = ({ 
  transcriptData,
  meetingIntelligence,
  duration = 0,  // in seconds
  currentTime = 0,  // in seconds
  onTimeChange 
}) => {
  const theme = useTheme();
  const [speakerColors, setSpeakerColors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState(null);

  const open = Boolean(anchorEl);
  const { transcript } = transcriptData || {};
  const { utterances = [], speakerNames = [] } = transcript || {};

  const sortedSpeakerNames = useMemo(() =>
    [...speakerNames].sort((a, b) => a.localeCompare(b)),
  [speakerNames]);

  const colors = useMemo(() => [
    theme.palette.primary.main,
    theme.palette.secondary.main
  ], [theme.palette.primary.main, theme.palette.secondary.main]);

  useEffect(() => {
    if (sortedSpeakerNames.length > 0) {
      const newSpeakerColors = {};
      sortedSpeakerNames.forEach((speaker, index) => {
        newSpeakerColors[speaker] = colors[index % colors.length];
      });
      setSpeakerColors(newSpeakerColors);
    }
  }, [sortedSpeakerNames, colors]);

  const handleTimelineClick = (event, timelineElement) => {
    const rect = timelineElement.getBoundingClientRect();
    const clickPosition = event.clientX - rect.left;
    const percentClick = (clickPosition / rect.width) * 100;
    const timeClick = (duration * percentClick) / 100;
    
    if (onTimeChange) {
      onTimeChange(timeClick);
      trackInteraction.buttonClick('timeline_speaker_navigation');
    }
  };

  const handlePopoverOpen = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null);
  };

  const handleJumpToTopic = (startTime) => {
    if (onTimeChange) {
      onTimeChange(startTime / 1000);
    }
  };

  const calculateItemPosition = (startTime, endTime) => {
    const durationMs = duration * 1000;
    const start = (startTime / durationMs) * 100;
    const width = Math.max(((endTime - startTime) / durationMs) * 100, 2);
    return { left: `${start}%`, width: `${width}%` };
  };

  const handleSignificantEventClick = (event, e) => {
    trackInteraction.buttonClick('timeline_significant_event');
    handlePopoverOpen(e,
      <SignificantEventInfoCard
        event={event}
        open={true}
        onClose={handlePopoverClose}
        onJumpToEvent={() => handleJumpToTopic(event.startTime)}
      />
    );
  };

  const handleTopicClick = (topic, e) => {
    trackInteraction.buttonClick('timeline_topic_card');
    handlePopoverOpen(e,
      <TopicInfoCard
        topic={topic}
        open={true}
        onClose={handlePopoverClose}
        onJumpToTopic={handleJumpToTopic}
      />
    );
  };

  if (!transcriptData || !transcript || utterances.length === 0 || duration === 0) {
    return <Typography>Loading transcript data...</Typography>;
  }

  return (
    <Box sx={{
      position: 'relative',
      p: 2,
      bgcolor: 'background.paper',
      borderRadius: 1,
      boxShadow: 1,
      width: '100%'
    }}>
      {/* Topics Timeline */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ 
          fontWeight: 'bold', 
          mb: 1,
          color: 'text.secondary'
        }}>
          Topics
        </Typography>
        <Box sx={{ 
          position: 'relative', 
          height: TIMELINE_ROW_HEIGHT, 
          bgcolor: 'grey.100', 
          borderRadius: 1,
          overflow: 'visible'
        }}>
          {meetingIntelligence?.topics?.map((topic, index) => {
            const position = calculateItemPosition(topic.startTime, topic.endTime);
            return (
              <Tooltip 
                key={index}
                title={`${formatTimeDisplay(topic.startTime)} - ${formatTimeDisplay(topic.endTime)}`}
              >
                <Chip
                  label={topic.name}
                  size="small"
                  sx={{
                    position: 'absolute',
                    left: position.left,
                    width: position.width,
                    height: CHIP_HEIGHT,
                    bgcolor: alpha(theme.palette.info.main, 0.8),
                    color: 'common.white',
                    fontSize: '0.75rem',
                    border: `1px solid ${theme.palette.info.main}`,
                    '&:hover': {
                      bgcolor: theme.palette.info.main,
                      transform: 'scale(1.02)',
                    },
                    transition: theme.transitions.create(['transform', 'background-color']),
                    zIndex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={(e) => handleTopicClick(topic, e)}
                />
              </Tooltip>
            );
          })}
        </Box>
      </Box>

      {/* Significant Events Timeline */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ 
          fontWeight: 'bold', 
          mb: 1,
          color: 'text.secondary'
        }}>
          Significant Events
        </Typography>
        <Box sx={{ 
          position: 'relative', 
          height: TIMELINE_ROW_HEIGHT, 
          bgcolor: 'grey.100', 
          borderRadius: 1
        }}>
          {meetingIntelligence?.significantEvents?.map((event, index) => {
            const position = calculateItemPosition(event.startTime, event.endTime);
            return (
              <Tooltip 
                key={index}
                title={`${formatTimeDisplay(event.startTime)} - ${formatTimeDisplay(event.endTime)}`}
              >
                <Chip
                  label={event.eventName}
                  size="small"
                  sx={{
                    position: 'absolute',
                    left: position.left,
                    width: position.width,
                    minWidth: '75px',
                    height: CHIP_HEIGHT,
                    bgcolor: alpha(theme.palette.info.main, 0.8),
                    color: 'common.white',
                    fontSize: '0.75rem',
                    border: `1px solid ${theme.palette.info.main}`,
                    '&:hover': {
                      bgcolor: theme.palette.info.main,
                      transform: 'scale(1.02)',
                    },
                    transition: theme.transitions.create(['transform', 'background-color']),
                    zIndex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={(e) => handleSignificantEventClick(event, e)}
                />
              </Tooltip>
            );
          })}
        </Box>
      </Box>

      {/* Speaker Timelines */}
      {sortedSpeakerNames.map((speaker) => (
        <Box key={speaker} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ 
            fontWeight: 'bold', 
            mb: 1,
            color: 'text.secondary'
          }}>
            {speaker}
          </Typography>
          <Box 
            sx={{ 
              position: 'relative', 
              height: TIMELINE_ROW_HEIGHT, 
              bgcolor: 'grey.100', 
              borderRadius: 1,
              cursor: 'pointer'
            }}
            onClick={(e) => handleTimelineClick(e, e.currentTarget)}
          >
            {utterances
              .filter(entry => entry.speakerName === speaker)
              .map((entry, index) => (
                <Tooltip 
                  key={index}
                  title={`${formatTimeDisplay(entry.start)} - ${formatTimeDisplay(entry.end)}`}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      left: `${(entry.start / (duration * 1000)) * 100}%`,
                      width: `${((entry.end - entry.start) / (duration * 1000)) * 100}%`,
                      height: '100%',
                      bgcolor: speakerColors[speaker],
                      borderRadius: 1,
                      '&:hover': {
                        opacity: 0.8,
                      },
                      transition: theme.transitions.create('opacity'),
                    }}
                  />
                </Tooltip>
              ))}
          </Box>
        </Box>
      ))}

      {/* Playhead */}
      <Box
        sx={{
          position: 'absolute',
          left: `${(currentTime / duration) * 100}%`,
          height: '100%',
          width: '2px',
          bgcolor: 'error.main',
          top: 0,
          zIndex: 2,
        }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {popoverContent}
      </Popover>
    </Box>
  );
};

const formatTimeDisplay = (ms) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

export default HeartbeatTimeline;
