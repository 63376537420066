import React from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Link,
  useTheme,
  Stack
} from '@mui/material';
import {
  LocationOn,
  Email,
  Phone,
  Business,
  Person,
} from '@mui/icons-material';
import orangeWord from '../../images/logos/Orange/Wordmark.png';


const Contact = () => {
  const theme = useTheme();

  const styles = {
    pageContainer: {
      minHeight: '100vh',
      backgroundColor: theme.palette.grey[50],
      py: 8,
      px: 2,
    },
    logoContainer: {
      width: '200px',
      marginBottom: 4,
      marginX: 'auto',
      '& img': {
        width: '100%',
        height: 'auto',
      }
    },
    title: {
      mb: 1,
      fontWeight: 'bold',
    },
    subtitle: {
      color: theme.palette.text.secondary,
      mb: 6,
    },
    card: {
      height: '100%',
      boxShadow: theme.shadows[3],
      '&:hover': {
        boxShadow: theme.shadows[6],
        transition: 'box-shadow 0.3s ease-in-out',
      },
    },
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      mb: 2,
      fontWeight: 'bold',
    },
    infoRow: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 2,
      mb: 2,
    },
    icon: {
      color: theme.palette.text.secondary,
    },
    contactLink: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  };

  return (
    <Box sx={styles.pageContainer}>
      <Container maxWidth="lg">
        {/* Logo Section - remains the same */}
        <Box sx={styles.logoContainer}>
          <img 
            src={orangeWord} 
            alt="Elevaide B.V." 
            style={{ 
              display: 'block',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Box>

        <Typography variant="h4" component="h1" sx={styles.title} align="center">
          Contact Us
        </Typography>
        <Typography variant="h6" sx={styles.subtitle} align="center">
          We're here to help and answer any questions you might have
        </Typography>

        <Grid container spacing={4}>
          {/* Company Information Card - remains the same */}
          <Grid item xs={12} md={6}>
            <Card sx={styles.card}>
              <CardContent>
                <Typography variant="h5" sx={styles.cardTitle}>
                  <Business /> Company Details
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle1" fontWeight="medium">
                      Legal Name
                    </Typography>
                    <Typography color="text.secondary">
                      Elevaide B.V.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1" fontWeight="medium">
                      Registration
                    </Typography>
                    <Typography color="text.secondary">
                      KVK: 94485682
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          {/* Contact Information Card */}
          <Grid item xs={12} md={6}>
            <Card sx={styles.card}>
              <CardContent>
                <Typography variant="h5" sx={styles.cardTitle}>
                  <Email /> Contact Information
                </Typography>
                <Stack spacing={2}>
                  {/* New Contact Person Section */}
                  <Box sx={styles.infoRow}>
                    <Person sx={styles.icon} />
                    <Box>
                      <Typography variant="subtitle1" fontWeight="medium">
                        Main Contact
                      </Typography>
                      <Typography color="text.secondary">
                        Jeroen van Velzen, Co-founder & CEO
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={styles.infoRow}>
                    <LocationOn sx={styles.icon} />
                    <Box>
                      <Typography variant="subtitle1" fontWeight="medium">
                        Address
                      </Typography>
                      <Typography color="text.secondary">
                        Van Vollenhovenlaan 16 A
                        <br />
                        2111 HH Aerdenhout
                        <br />
                        Netherlands
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={styles.infoRow}>
                    <Phone sx={styles.icon} />
                    <Box>
                      <Typography variant="subtitle1" fontWeight="medium">
                        Phone
                      </Typography>
                      <Link href="tel:+31613131717" sx={styles.contactLink}>
                        +31613131717
                      </Link>
                    </Box>
                  </Box>

                  <Box sx={styles.infoRow}>
                    <Email sx={styles.icon} />
                    <Box>
                      <Typography variant="subtitle1" fontWeight="medium">
                        Email
                      </Typography>
                      <Link href="mailto:jeroen@elevaide.com" sx={styles.contactLink}>
                        jeroen@elevaide.com
                      </Link>
                    </Box>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
